import styled from 'styled-components';
import { ContainerColumnStart } from '../../styles/Common';
import { deviceMax } from '../../styles/MediaQuery';
import {
    blue,
    citronBackground,
    darkGreen,
    greyBorderButton,
    greyLight,
    limaBackground,
    white,
} from '../../styles/Variables';

export const SecondHandMachinesDetailsWrapper = styled(ContainerColumnStart)`
    margin: 0 auto;

    @media ${deviceMax.tablet} {
        width: calc(100% - 30px);
    }
`;

export const ProductTitle = styled.h2`
    color: ${blue};
    margin: 43px 0 17px 0;
    font-size: 2.5rem;
    line-height: 2.75rem;
    letter-spacing: 0.025em;
    font-family: 'NeueHelveticaPro77CondensedBold', Helvetica, sans-serif;
`;

export const NavigationWrapper = styled.div`
    display: flex;
    flex-direction: row;

    @media ${deviceMax.tablet} {
        flex-direction: column;
        width: 100%;
    }
`;

interface NavigationItemProps {
    readonly isActive?: boolean;
}

export const NavigationItem = styled.div<NavigationItemProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => (props.isActive ? darkGreen : white)};
    border-top: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    background: ${(props) =>
        props.isActive
            ? white
            : `transparent linear-gradient(${limaBackground}, ${citronBackground}) repeat scroll 0 0;`};
    width: 200px;
    padding: 15px 0;
    cursor: pointer;

    &:nth-child(1) {
        border-left: 1px solid #e3e3e3;
    }

    @media ${deviceMax.tablet} {
        width: 100%;
        border-left: 1px solid #e3e3e3;
    }
`;

export const ContentWrapper = styled.div`
    width: 100%;
    background-color: ${greyLight};
    border: 1px solid ${greyBorderButton};
    height: auto;
`;

interface CardsContainerProps {
    isVisible?: boolean;
    hasAttachment?: boolean;
}

export const CardsContainer = styled.div<CardsContainerProps>`
    display: ${(props) => (props.isVisible ? 'grid' : 'none')};
    grid-template-columns: ${(props) => (props.hasAttachment ? '1fr' : '1fr 1fr')};
    padding-bottom: 20px;

    @media ${deviceMax.tablet} {
        grid-template-columns: 1fr;
        padding-top: 20px;
    }
`;

export const CardImage = styled.img`
    max-height: 500px;
    margin: 20px 30px 20px 10px;

    @media ${deviceMax.tablet} {
        margin: 0 auto;
        max-width: calc(100% - 30px);
        padding: 0 10px 0 20px;
    }
`;

export const TextContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-left: 1px solid ${white};
    padding: 25px 0 0 20px;

    * {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 13px;
        box-sizing: border-box;
        margin: 0;
    }
`;

export const PriceInfoWrapper = styled.div`
    margin-top: 20px;

    a {
        color: #928b8b;
    }
`;

export const TextContentTitle = styled.h3`
    color: ${blue};
`;

export const PropertyBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const PropertyItemBox = styled.div`
    display: grid;
    grid-template-columns: 30% 1fr;
    word-break: break-word;
    justify-items: start;
    gap: 10px;

    @media ${deviceMax.tablet} {
        display: flex;
        flex-direction: column;
        gap: 0;
    }
`;

export const PropertyKey = styled.p`
    font-weight: 700;
    margin: 10px 5px 0 0;
    line-height: 1.4;
`;

export const PropertyValueBox = styled.div`
    display: flex;
    flex-direction: column;
    word-break: break-word;
`;

export const PropertyValue = styled.div`
    margin-top: 10px;
    line-height: 1.4;
    max-width: 90%;
    span {
        color: ${blue};
        font-weight: 700;
    }
`;

export const AttachmentsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
`;

export const AttachmentImage = styled.img`
    width: 200px;
    height: 200px;
    padding: 20px 10px;
`;
