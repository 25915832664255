import styled from 'styled-components';
import { ContainerColumnStart, SectionHeadline, SectionTitle } from '../../styles/Common';
import { deviceMax } from '../../styles/MediaQuery';
import { blue, paragraphMargin } from '../../styles/Variables';

export const SecondHandMachinesContainer = styled(ContainerColumnStart)`
    margin: 0 auto;

    @media ${deviceMax.tabletM} {
        width: calc(100% - 30px);
    }
`;

export const SecondHandMachinesTitle = styled.h1`
    color: ${blue};
    font-size: 2.5rem;
    line-height: 2.75rem;
    letter-spacing: 0.025em;
    margin-top: ${paragraphMargin};
    word-break: break-all;
    font-family: 'NeueHelveticaPro77CondensedBold', Arial, Helvetica;
    word-break: break-word;
`;

export const SecondHandMachinesSectionTitle = styled(SectionTitle)`
    margin: 0;
    padding: 0;
    margin-top: ${paragraphMargin};
    font-family: 'NeueHelveticaPro57Condensed', Helvetica, Arial;
    font-weight: 700;
`;

export const SecondHandMachinesSectionSubtitle = styled.p`
    font-family: 'NeueHelveticaPro77CondensedBold', Arial, Helvetica;
    font-size: 15px;
    font-weight: 900;
    text-transform: uppercase;
    margin-top: 2rem;
`;

export const SecondHandMachinesTextSection = styled.p`
    font-size: 0.875rem;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.5rem;
    padding: 0;
`;

export const PersonWrapper = styled.div`
    width: 100%;
    margin-top: 3rem;
`;

export const PersonHeadline = styled(SectionHeadline)`
    font-size: 15px;
    padding: 0;
    margin: 0;
`;

export const InformationWrapper = styled.div`
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-gap: 20px;

    * {
        font-size: 13px;
        font-family: Arial, Helvetica, sans-serif;
        line-height: 1.4;
    }
`;

export const InformationNote = styled.p`
    color: ${blue};
    font-weight: 700;
`;

interface InformationNoteTextProps {
    isDetailPage?: boolean;
}

export const InformationNoteText = styled.div<InformationNoteTextProps>`
    margin-left: ${(props) => (props.isDetailPage ? '40px' : '25px')};

    * {
        font-size: 13px;
        font-family: Arial, Helvetica, sans-serif;

        a {
            color: ${blue};
            font-size: 14px;
        }
    }
`;

export const SecondHandMachinesSectionBox = styled(ContainerColumnStart)`
    width: 100%;
`;
